<template>
  <div  class="zjdj">
    <div class="djyl">
    </div>
    <div class="content">
      <el-breadcrumb separator-class="el-icon-arrow-right" class="mbx">
        <el-breadcrumb-item  :to="{ path: '/djyl' }">党建引领</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/zjdj' }">走进党建</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="list">
        <div style="text-align: center">
          <el-input placeholder="请输入内容" v-model="keyword" style="width: 400px;margin: 19px auto 35px auto;">
            <el-button slot="append" icon="el-icon-search" class="btn"></el-button>
          </el-input>
        </div>
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="全部" name="0"></el-tab-pane>
          <el-tab-pane label="政治建设" name="1"></el-tab-pane>
          <el-tab-pane label="思想建设" name="2"></el-tab-pane>
          <el-tab-pane label="组织建设" name="3"></el-tab-pane>
          <el-tab-pane label="作风建设" name="4"></el-tab-pane>
          <el-tab-pane label="纪律建设" name="5"></el-tab-pane>
          <el-tab-pane label="制度建设" name="6"></el-tab-pane>
        </el-tabs>
        <div>
          <div v-for="(li,index) in lists" class="flex lists" @click="gotoxq">
            <div class="img"></div>
            <div class="flex-1">
              <div class="h1 multi-line-ellipsis2">{{li.h1}}</div>
              <div class="h2 .multi-line-ellipsis3">{{li.h2}}</div>
              <div class="time">{{li.time}}</div>
            </div>
          </div>
          <div class="fenye">
            <el-pagination
                @current-change="handleCurrentChange"
                :current-page="pageNo"
                page-size="15"
                :total="total">
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "zjdj",
  data(){
    return {
      keyword:'',
      activeName:'0',
      pageNo:1,
      total:0,
      lists:[{h1:'111',h2:'222',time:'20125-52'},{h1:'111',h2:'222',time:'20125-52'}]
    }
  },
  methods:{
    gotoxq(){
      this.$router.push({
        path:'/zjdjxq'
      })
    },
    loadlist(){

    },
    handleCurrentChange(val) {
      this.pageNo=val
      this.loadlist()
    },
  }
}
</script>

<style lang="scss" scoped>
.fenye{
  text-align: center;
  padding: 30px 0;
}
.multi-line-ellipsis2 {
  display: -webkit-box; /* 必须设置此项才能生效 */
  -webkit-line-clamp: 2; /* 限制最大行数 */
  -webkit-box-orient: vertical; /* 垂直布局 */
  overflow: hidden; /* 超出部分隐藏 */
  text-overflow: ellipsis; /* 显示省略号 */
}
.multi-line-ellipsis3 {
  display: -webkit-box; /* 必须设置此项才能生效 */
  -webkit-line-clamp: 3; /* 限制最大行数 */
  -webkit-box-orient: vertical; /* 垂直布局 */
  overflow: hidden; /* 超出部分隐藏 */
  text-overflow: ellipsis; /* 显示省略号 */
}
  .djyl{
    background: url("../../assets/djyl/djylbg.png") no-repeat;
    background-size: 100% 100%;
    padding-top: 140px;
    color: #fff;
  }
  .content{
    width: 1200px;
    margin: 20px auto;
    .lists{
      padding: 26px;
      border-bottom: 1px solid #D7D7D7;
      line-height: 30px;
      cursor: pointer;
      .img{
        width: 350px;
        height: 190px;
        margin-right: 46px;
        background: red;
        border-radius: 8px;
      }
      .h1{
        margin: 26px 0;
        font-size: 16px;
      }
      .h2{
        color: #3B3E45;
      }
      .time{
        color: #B4B4B4;
        text-align: right;
        margin-top: 18px;
        font-size: 12px;
      }
    }
  }
</style>